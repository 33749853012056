export enum AppTypeEnum {
  LEGACY = "legacy",
  EVAL = "eval",
  SAFETY_PORTAL = "safety_portal",
  APP = "app",
}

export enum SelfServeSpecialty {
  UNKNOWN = 0,
  COLONOSCOPY,
  UROLOGY,
  OTHER,
}

export enum SeverityEnum {
  SEVERITY_HIGHEST = "severity_highest",
  SEVERITY_HIGH = "severity_high",
  SEVERITY_MEDIUM = "severity_medium",
  SEVERITY_LOW = "severity_low",
  SEVERITY_GOOD = "severity_good",
}

export enum SentimentEnum {
  SENTIMENT_BAD = "setiment_bad",
  SENTIMENT_MEDIUM = "setiment_medium",
  SENTIMENT_NEUTRAL = "setiment_neutral",
  SENTIMENT_GOOD = "setiment_good",
}

// HACK: this is a temporary solution to identify that the app is safety portal
export const HACK_IS_SAFETY_PORTAL =
  window.location.host.includes("safetyportal");

// HACK: this is a temporary solution to identify that the app is eval
export const HACK_IS_EVAL =
  window.location.host.includes("eval.hippocraticdev.com") ||
  window.location.host.includes("myelin");

export const NULL = "null";

export const APP_HEADER_HEIGHT = 64;

export const APP_SUB_HEADER_HEIGHT = 48;

export const DEFAULT_PAGE_SIZE = 25;
