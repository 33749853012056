import { createTheme, alpha } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {
  addAnnotationColors,
  addBasicColors,
  addSeverityColors,
  addKickoutColors,
} from "./BaseTheme";

export let theme = createTheme();

theme = createTheme(theme, {
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: "#27A966",
      },
      name: "primary",
    }),
    primaryLight: theme.palette.augmentColor({
      color: {
        main: alpha("#27A966", 0.2),
      },
      name: "primaryLight",
    }),
    primaryDark: theme.palette.augmentColor({
      color: {
        main: alpha("#27A966", 0.7),
      },
      name: "primaryDark",
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: "#F1E48A",
      },
      name: "secondary",
    }),
    tertiary: theme.palette.augmentColor({
      color: {
        main: "#4DA9EA",
      },
      name: "tertiary",
    }),
    quaternary: theme.palette.augmentColor({
      color: {
        main: "#8a73c5",
      },
      name: "quaternary",
    }),
  },
});

theme = addAnnotationColors(theme);
theme = addKickoutColors(theme);
theme = addBasicColors(theme);
theme = addSeverityColors(theme);

interface MyelinThemeProviderProps {
  children: React.ReactNode;
}

const MyelinThemeProvider = ({ children }: MyelinThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MyelinThemeProvider;
