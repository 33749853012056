import { useState } from "react";

const useDialog = (defaultOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const toggleDialog = () => setIsOpen((prev) => !prev);
  return { isOpen, openDialog, closeDialog, toggleDialog, setIsOpen };
};

export default useDialog;
