import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useIsSuperAdmin from "~/hooks/useIsSuperAdmin";
import { useGetVersionQuery } from "~/api/GeneralApi";
import useCurrentUser from "~/hooks/useCurrentUser";
import useIsUserType from "~/hooks/useIsUserType";
import { UserTypeEnum } from "~/constants/UserConstants";

const EnvironmentHeader = () => {
  const isAdmin = useIsUserType(UserTypeEnum.ADMIN);
  const isSuperAdmin = useIsSuperAdmin();
  const currentUser = useCurrentUser();
  const {
    data: versionData,
    isPending: versionPending,
    isError: versionError,
  } = useGetVersionQuery(true);
  if (!isAdmin || versionPending || versionError) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          bgcolor: "grey.300",
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
          px: 4,
          py: "2px",
        }}
      >
        <Typography
          variant={"body2"}
          color="text.primary"
          sx={{
            fontSize: "0.75rem",
            lineHeight: 1.5,
            fontWeight: 600,
          }}
        >{`${currentUser.partner_name}`}</Typography>
        {isSuperAdmin && versionData && (
          <Typography
            variant={"body2"}
            color="text.primary"
            sx={{
              fontSize: "0.575rem",
              lineHeight: 1.5,
              ml: 1,
            }}
          >{`(${versionData.version})`}</Typography>
        )}
      </Box>
    </>
  );
};

export default EnvironmentHeader;
