import { UserTypeEnum } from "~/constants/UserConstants";
import useCurrentUser from "~/hooks/useCurrentUser";
import { userHasPermissionOrHigher } from "~/utils/UserUtils";

const useIsUserType = (userType: UserTypeEnum) => {
  const user = useCurrentUser();
  return userHasPermissionOrHigher(user, userType);
};

export default useIsUserType;
