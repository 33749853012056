import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  Suspense,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { HttpError } from "~/utils/ApiUtils";
import useDialog from "~/hooks/useDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { getCurrentUser, updateCurrentUserDefaults } from "~/api/UserApi";
import { AUTH_TOKEN_KEY, AUTH_CSRF_KEY, getRootUrl } from "~/utils/ApiUtils";
import { UserType } from "~/types/UserTypes";
import { getVersion, useExchangeTokenQuery } from "~/api/GeneralApi";
import { logout } from "~/api/GeneralApi";
import callConfigService from "~/services/CallConfigService";
import Loading from "~/components/common/Loading";

const _signOut = () => {
  logout();
  localStorage.removeItem(AUTH_TOKEN_KEY);
  window.location.href = "/login";
};

const _redirectWithSavedValue = (defaultRedirect?: string) => {
  let redirectPath = localStorage.getItem("redirectPath");
  if (!redirectPath && !defaultRedirect) {
    return;
  }
  if (redirectPath && redirectPath.includes("login")) {
    console.error("redirectPath includes login - redirecting to /");
    redirectPath = "/";
  }
  if (redirectPath) {
    localStorage.removeItem("redirectPath");
    console.log(`Redirecting to ${redirectPath}`);
    window.location.href = redirectPath;
  } else if (defaultRedirect) {
    window.location.href = defaultRedirect;
  }
};

interface AuthenticationContextType {
  user: UserType | null;
  setUser: (user: UserType) => void;
  signOut: () => void;
  isMyelin: boolean | null;
  serverVersion: string | null;
  setServerVersion: (version: string) => void;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  user: null,
  setUser: (user: UserType) => {},
  signOut: _signOut,
  isMyelin: null,
  serverVersion: null,
  setServerVersion: (version: string) => {},
});

const UnauthenticatedView = () => {
  const context = useContext(AuthenticationContext);
  const { data } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      try {
        const currentUser = await getCurrentUser();
        const versionPayload = await getVersion();
        _redirectWithSavedValue();
        return { currentUser, versionPayload };
      } catch (error: unknown) {
        const httpError = error as HttpError;
        if (httpError.statusCode === 401) {
          // redirect to login
          console.log("Not logged in - redirecting to login");
          // reset the userdefaults in local storage
          updateCurrentUserDefaults(null);
          // save the existing path in local storage
          const url = window.location.href;
          if (!url.includes("login")) {
            localStorage.setItem("redirectPath", url);
          }
          window.location.href = `${getRootUrl()}/login`;
          return null;
        }
      }
    },
  });

  useEffect(() => {
    if (data) {
      context.setUser(data.currentUser);
      context.setServerVersion(data.versionPayload.version);
    }
  }, [data, context]);

  return null;
};

const LoginRedirectHandlerView = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");

  const tokenPayload = code && state ? { code, state } : undefined;

  const { data: exchangeResult, isError: tokenError } =
    useExchangeTokenQuery(tokenPayload);

  useEffect(() => {
    if (exchangeResult) {
      console.log("setting token from login_redirect", exchangeResult);
      localStorage.setItem(AUTH_TOKEN_KEY, exchangeResult.accessToken!);
      localStorage.setItem(AUTH_CSRF_KEY, exchangeResult.csrf!);
      _redirectWithSavedValue("/");
    }
    // if there is an error, redirect back to login
    if (tokenError) {
      console.error("Error exchanging token", tokenError);
      window.location.href = `${getRootUrl()}/login`;
    }
  }, [exchangeResult, tokenError]);

  return null;
};

/*
 * Render the unauthenticated view
 * If there is a query param for token, set the token in local storage
 * Attempt to fetch the user - if it gets a 401 then redirect to login
 * If it gets a user, set the user in the context and render the children
 */

const AuthenticatorProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = useState<UserType>();
  const [serverVersion, setServerVersion] = useState<string>();
  const { isOpen: tokenExpired, setIsOpen: setTokenExpired } = useDialog(false);
  const tokenRef = useRef<ReturnType<typeof setInterval>>();

  // call configurations are initialized in the service because they are potentially
  // used outside of the context of a react component
  const { data: callConfigurations, isError: configError } = useQuery({
    queryKey: ["callConfigurations"],
    queryFn: async () => {
      return await callConfigService.initialize();
    },
    enabled: !!user,
    staleTime: Infinity,
  });

  // check for token expiration every second and show a logout dialog if it has expired
  useEffect(() => {
    tokenRef.current = setInterval(() => {
      if (user && !tokenExpired) {
        const token = localStorage.getItem(AUTH_TOKEN_KEY);
        if (!token) {
          setTokenExpired(true);
        }
      }
    }, 1000);
    return () => {
      if (tokenRef.current) {
        clearInterval(tokenRef.current);
      }
    };
  }, [user, tokenExpired, setTokenExpired]);

  let content;
  if (!user) {
    content = (
      <Routes>
        <Route path="/login_redirect" element={<LoginRedirectHandlerView />} />
        <Route path="/*" element={<UnauthenticatedView />} />
      </Routes>
    );
  } else if (!callConfigurations && !configError) {
    console.log("Call configurations not loaded yet.");
    content = null;
  } else {
    content = children;
  }

  const isMyelin = window.location.hostname.includes("myelin");

  const context = {
    user: user ?? null,
    setUser,
    signOut: _signOut,
    isMyelin,
    serverVersion: serverVersion ?? null,
    setServerVersion,
  };

  return (
    <AuthenticationContext.Provider value={context}>
      <Dialog open={tokenExpired} fullWidth>
        <DialogTitle>Session Expired</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session has expired. Please sign in again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_signOut} color="primary">
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
      <Suspense fallback={<Loading />}>{content}</Suspense>
    </AuthenticationContext.Provider>
  );
};

export default AuthenticatorProvider;
