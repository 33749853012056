import "./App.css";
import { lazy } from "react";
import MyelinThemeProvider from "~/themes/MyelinThemeProvider";
import SnowThemeProvider from "~/themes/SnowThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import EnvironmentHeader from "~/components/dev/EnvironmentHeader";
import useDocumentTitle from "~/hooks/useDocumentTitle";
import { AppTypeEnum } from "~/constants/UIConstants";

const LazyRootRoutes = lazy(() => import("~/routes/RootRoute"));
const LazyRootSafetyPortalRoutes = lazy(
  () => import("~/routes/RootRoute.safetyportal")
);
const LazyRootAppRoutes = lazy(() => import("~/routes/RootRoute.app"));
const LazyRootEvalRoutes = lazy(() => import("~/routes/RootRoute.eval"));

function App() {
  useDocumentTitle();
  const Provider =
    APP_TYPE === AppTypeEnum.EVAL ? MyelinThemeProvider : SnowThemeProvider;

  let rootRoutes;
  if (APP_TYPE === AppTypeEnum.APP) {
    rootRoutes = <LazyRootAppRoutes />;
  } else if (APP_TYPE === AppTypeEnum.SAFETY_PORTAL) {
    rootRoutes = <LazyRootSafetyPortalRoutes />;
  } else if (APP_TYPE === AppTypeEnum.EVAL) {
    rootRoutes = <LazyRootEvalRoutes />;
  } else {
    rootRoutes = <LazyRootRoutes />;
  }

  return (
    <Provider>
      <CssBaseline />
      <EnvironmentHeader />
      {rootRoutes}
    </Provider>
  );
}

export default App;
