import { useEffect } from "react";

const useDocumentTitle = (title?: string) => {
  const isMyelin = window.location.hostname.includes("myelin");
  const baseTitle = BASE_TITLE ?? (isMyelin ? "Myelin" : "Hippocratic AI");
  useEffect(() => {
    const titleOrEmpty = title ? ` | ${title}` : "";
    document.title = `${baseTitle}${titleOrEmpty}`;
  }, [title, baseTitle]);
};

export default useDocumentTitle;
