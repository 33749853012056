import React from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import SumoLogger from "sumo-logger";
import { getVersion } from "~/api/GeneralApi";

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

class LoggingService {
  sumo: SumoLogger | null = null;

  async init() {
    // Don't initialize Sentry or Sumo in development
    if (import.meta.env.VITE_IS_DEV) {
      return;
    }
    const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
    const sumoLogicCollectorUrl = import.meta.env.VITE_SUMOLOGIC_COLLECTOR_URL;

    const serverVersion = await getVersion();

    //  Initializing Sentry
    if (sentryDSN) {
      Sentry.init({
        environment: this.getEnvironment(),
        release: serverVersion.version,
        dsn: sentryDSN,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    // Initializing SumoLogic
    if (sumoLogicCollectorUrl) {
      this.sumo = new SumoLogger({
        endpoint: sumoLogicCollectorUrl,
        sourceName: `front_end_${this.getEnvironment()}`,
        hostName: window.location.hostname,
      });
    }
  }

  getEnvironment() {
    // This should be getting set now
    if (import.meta.env.VITE_ENV) {
      return import.meta.env.VITE_ENV;
    }
    // extract the subdomain from the url
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      return "localhost";
    }
    return "unknown";
  }

  logMessage(message: string, level: "INFO" | "ERROR", ...args: any) {
    if (this.sumo) {
      // generate a string that is the message and the args (stringified)
      let messageWithArgs = message;
      const timestamp = new Date().toISOString();
      try {
        messageWithArgs = `${level} FE: ${timestamp} - ${message}${(args || [])
          .map((arg: any) => {
            if (arg instanceof Error) {
              return arg.message;
            }
            return JSON.stringify(arg);
          })
          .join(",")}`;
      } catch (e) {
        console.error("Error creating logging message", e);
      }
      this.sumo.log(messageWithArgs);
    }
    if (level === "INFO") {
      console.log(message, ...args);
    } else {
      console.error(message, ...args);
    }
  }
}

const loggingService = new LoggingService();

export const logMessage = (message: string, ...args: any) => {
  loggingService.logMessage(message, "INFO", ...args);
};

export const logError = (message: string, ...args: any) => {
  loggingService.logMessage(message, "ERROR", ...args);
};

export default loggingService;
