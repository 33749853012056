import { enumToOptions } from "~/utils/FormUtils";

export enum UserTypeEnum {
  ADMIN = "admin",
  GENERAL = "general",
  EVALUATOR = "evaluator",
  SUPER_ADMIN = "super_admin",
}

export enum UserRoleEnum {
  GENERAL = "general",
  INVESTOR = "investor",
  INTERNAL = "internal",
  PHYSICIAN = "physician",
  NURSE = "nurse",
  NURSE_PRACTITIONER = "nurse_practitioner",
  PHYSICIAN_ASSISTANT = "physician_assistant",
  SOCIAL_WORKER = "social_worker",
  PATIENT = "patient",
  ACTOR = "actor",
  WRITER = "writer",
}

export enum MFATypeEnum {
  SMS = "sms",
  EMAIL = "email",
  // APP = "app",
}

export const MFATypeOptions = enumToOptions(MFATypeEnum);

export enum PhysicianRoleEnum {
  RESIDENT = "resident",
  FELLOW = "fellow",
  ATTENDING = "attending",
}

export const UserTypeOptions = enumToOptions(UserTypeEnum);
export const UserRoleOptions = enumToOptions(UserRoleEnum);
export const DoctorRoleOptions = enumToOptions(PhysicianRoleEnum);

export const UNKNOWN_USER_ID = "00000000-0000-0000-0000-000000000000";
export const AUTO_EVAL_SYSTEM_USER_ID = "55555555-5555-5555-5555-000000000001";

export const UserRoleOptionList = UserRoleOptions.map((item) => item.value);
