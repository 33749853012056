import { FormOptionType, SectionedFormOptionType } from "~/types/UITypes";
import { formatEnumValue } from "./StringUtils";

export const getKeyValueOptions = (options: Record<string, string>) => {
  return Object.keys(options).map((key) => {
    return {
      value: key,
      label: options[key],
    };
  });
};

export const createSurveyOptions = (
  options?: string[],
  templateParams?: Record<string, string>
) => {
  if (!options) {
    return [];
  }
  return options.map((option) => {
    return {
      value: option,
      label: getTransformedSurveyString(option, templateParams),
    };
  });
};

export const getTransformedSurveyString = (
  templateStr: string,
  templateParams?: Record<string, string>
) => {
  if (!templateParams) {
    return templateStr;
  }
  let transformedStr = templateStr;
  Object.keys(templateParams).forEach((key) => {
    transformedStr = transformedStr.replace(key, templateParams[key]);
  });
  return transformedStr;
};

export const getSurveyTemplateParams = (
  nurseName: string,
  partnerName?: string
) => {
  return {
    "%NURSE_NAME%": nurseName,
    "%PARTNER_NAME%": partnerName ?? "Partner",
  };
};

export const enumToOptions = <T>(
  enumObj: Record<string, T>,
  disableFormatting?: boolean,
  remove_prefix?: number
): FormOptionType<T>[] => {
  return Object.keys(enumObj).map((key) => {
    let label = enumObj[key] as string;
    if (remove_prefix) {
      label = label.substring(remove_prefix);
    }
    return {
      value: enumObj[key],
      label: !disableFormatting ? formatEnumValue(label)! : label,
    };
  });
};

export const enumToList = <T>(enumObj: Record<string, T>): T[] => {
  return Object.keys(enumObj).map((key) => {
    return enumObj[key];
  });
};

export const getLabelFromValue = <T>(
  options: FormOptionType<T>[],
  value: T,
  defaultLabel?: string
): string | null => {
  const option = options.find((option) => {
    return option.value === value;
  });
  return option ? option.label : defaultLabel ?? null;
};

export const sanitizeAllFormInputsForSave = <T>(
  data: Record<string, any>
): T => {
  // if any data entry is { label: "label", value: "value" }, convert it to just "value"
  const sanitizedData = { ...data };
  Object.keys(sanitizedData).forEach((key) => {
    if (
      typeof sanitizedData[key] === "object" &&
      sanitizedData[key] &&
      sanitizedData[key].value &&
      sanitizedData[key].label
    ) {
      sanitizedData[key] = sanitizedData[key].value;
    }
  });
  return sanitizedData as T;
};

export const findFormOption = (
  options: FormOptionType<any>[] | SectionedFormOptionType<any>[],
  value: any
) => {
  if (!value || options.length === 0) {
    return;
  }
  if (options[0].hasOwnProperty("options")) {
    const sectionedOptions = options as SectionedFormOptionType<any>[];
    for (const section of sectionedOptions) {
      const option = section.options.find((option) => {
        return option.value === value;
      });
      if (option) {
        return option;
      }
    }
  } else {
    const formOptions = options as FormOptionType<any>[];
    return formOptions.find((option) => {
      return option.value === value;
    });
  }
};

export const stringsToOptions = (
  strings: string[]
): FormOptionType<string>[] => {
  return strings.map((str) => {
    return {
      value: str,
      label: str,
    };
  });
};
