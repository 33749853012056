import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { type SxProps } from "@mui/material";

interface LoadingProps {
  sx?: SxProps;
}

const Loading = ({ sx }: LoadingProps) => {
  return (
    <Box
      data-testid="loading"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexGrow: 1,
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
