import { getCallConfigurations } from "~/api/DomainApi";
import {
  CHF_SCRIPT_UUID,
  JANE_ANDERSON_PATIENT_UUID,
  RACHEL_UUID,
} from "~/constants/UUIDConstants";
import { CallConfigurationsType } from "~/types/CallTypes";
import { FormOptionType } from "~/types/UITypes";
import { CurrentUserDefaultsType } from "~/types/UserTypes";
import { generateCallUIConfigFromCallConfig } from "~/utils/CallConfigUtils";

class CallConfigService {
  private _callConfigurations: CallConfigurationsType | null = null;
  private _currentUserDefaults: CurrentUserDefaultsType | null = null;

  async initialize() {
    const configurations = await getCallConfigurations();
    if (!configurations) {
      console.error("Invalid response from getCallConfigurations.");
      throw new Error("Invalid response from getCallConfigurations.");
    }
    console.log("Call configurations initialized.", configurations);
    this._callConfigurations = configurations;
    return this._callConfigurations;
  }

  getDefaultCallConfig() {
    if (!this._callConfigurations) {
      throw new Error("Call configurations not initialized.");
    }
    return this._callConfigurations.defaults;
  }

  getDefaultCallUIConfig() {
    const defaultCallConfig = this.getDefaultCallConfig();
    const uiConfig = generateCallUIConfigFromCallConfig(defaultCallConfig);
    uiConfig.callConfig.agent_id = RACHEL_UUID;
    uiConfig.callConfig.script_id = CHF_SCRIPT_UUID;
    uiConfig.patientId = JANE_ANDERSON_PATIENT_UUID;
    return uiConfig;
  }

  getUserCallUIConfig() {
    if (!this._currentUserDefaults) {
      throw new Error("User defaults not initialized.");
    }
    return this._currentUserDefaults.callSettings;
  }

  supportedLlmModelsOptions() {
    return this._getFieldOptions("supported_llm_models");
  }
  supportedSupportModelsOptions() {
    return this._getFieldOptions("supported_support_models");
  }
  asrServicesOptions() {
    return this._getFieldOptions("asr_services");
  }
  supportEnginesOptions() {
    return this._getFieldOptions("support_engines");
  }
  ttsServicesOptions() {
    return this._getFieldOptions("tts_services");
  }

  private _getFieldOptions(field: string): FormOptionType<string>[] {
    if (!this._callConfigurations) {
      throw new Error("Call configurations not initialized.");
    }
    const options: string[] = (
      this._callConfigurations.options as Record<string, string[]>
    )[field];
    return options.map((option) => ({
      value: option,
      label: option,
    }));
  }
}

const callConfigService = new CallConfigService();
export default callConfigService;
