import lodashCloneDeep from "lodash/cloneDeep";
import lodashPartial from "lodash/partial";
import lodashUniq from "lodash/uniq";
import lodashIsNil from "lodash/isNil";
import lodashIsEqual from "lodash/isEqual";
import lodashIsEmpty from "lodash/isEmpty";
import lodashFlatten from "lodash/flatten";
import lodashGroupBy from "lodash/groupBy";
import lodashIntersection from "lodash/intersection";
import { v4, validate } from "uuid";

// ===== Javascript Utils =====

// We are trying to avoid importing lodash directly in components
// so we are wrapping the functions we need here. This is so we can
// avoid accidentally importing the entire library in a component.

export const cloneDeep = lodashCloneDeep;
export const partial = lodashPartial;
export const uuidv4 = v4;
export const uniq = lodashUniq;
export const isNil = lodashIsNil;
export const isEqual = lodashIsEqual;
export const isEmpty = lodashIsEmpty;
export const flatten = lodashFlatten;
export const groupBy = lodashGroupBy;
export const intersection = lodashIntersection;
export const validateUUID = validate;
