import {
  CallConfigType,
  CallDetailsType,
  CallUIConfigType,
} from "~/types/CallTypes";
import { BasePatientType } from "~/types/PatientTypes";
import {
  DEFAULT_TRANSCRIPT_SETTINGS,
  ConfigMethodEnum,
} from "~/constants/CallConfigConstants";
import { cloneDeep, isEqual } from "~/utils/GeneralUtils";
import { getCallTypeToMethod } from "~/utils/CallUtils";

export const generateCallConfigFromUIConfig = (
  uiConfig: CallUIConfigType,
  patient?: BasePatientType,
  method?: ConfigMethodEnum,
  phoneNumber?: string,
  partnerId?: string
): CallConfigType => {
  const config: CallConfigType = Object.assign(cloneDeep(uiConfig.callConfig), {
    phone_number: phoneNumber || "",
    method: method,
    patient: patient
      ? {
          patient_name: `${patient.firstName} ${patient.lastName}`,
        }
      : undefined,
    partner_id: partnerId,
  });
  return config;
};

export const generateCallUIConfigFromCallConfig = (
  callConfig: CallConfigType,
  method: ConfigMethodEnum = ConfigMethodEnum.PHONE,
  uiSettings = DEFAULT_TRANSCRIPT_SETTINGS
): CallUIConfigType => {
  const uiConfig: CallUIConfigType = {
    method,
    callConfig,
    uiSettings,
  };
  return uiConfig;
};

export const generateCallSupportEngines = (
  baseEngines: string[],
  {
    exclusion,
    inclusion,
  }: {
    exclusion?: string[];
    inclusion?: string[];
  }
) => {
  let filterEngines = baseEngines.filter(
    (engine) => !exclusion?.includes(engine)
  );
  filterEngines = filterEngines.concat(inclusion ?? []);
  // remove duplicates
  filterEngines = filterEngines.filter(
    (engine, index) => filterEngines.indexOf(engine) === index
  );
  return filterEngines;
};

export const isValidCallUIConfig = (uiConfig: CallUIConfigType) => {
  if (!uiConfig.patientId) {
    return false;
  }
  return true;
};

export const mergeCallConfig = (
  defaultCallConfig: CallConfigType,
  newCallConfig: CallConfigType
): CallConfigType => {
  // Overwrite the default other settings with the new other settings
  const mergedCallConfig = Object.assign(cloneDeep(defaultCallConfig), {
    ...newCallConfig,
    other_settings: Object.assign(
      cloneDeep(defaultCallConfig.other_settings) ?? {},
      newCallConfig.other_settings
    ),
  });
  return mergedCallConfig;
};

export const mergeCallUIConfig = (
  defaultCallUIConfig: CallUIConfigType,
  newCallUIConfig: CallUIConfigType
): CallUIConfigType => {
  const { callConfig: defaultCallConfig, ...defaultRest } = defaultCallUIConfig;
  const mergedCallConfig = mergeCallConfig(
    defaultCallConfig,
    newCallUIConfig.callConfig
  );
  const mergedConfig = Object.assign(cloneDeep(defaultRest), newCallUIConfig, {
    callConfig: mergedCallConfig,
  });
  console.log("Call UI Config A:", defaultCallUIConfig);
  console.log("Call UI Config B:", newCallUIConfig);
  console.log("Merged Config:", mergedConfig);
  return mergedConfig;
};

/**
 * Extract all the key and values where the value is different from the default config
 * @param newConfig
 * @param defaultConfig
 * @returns A diff object of the other settings between the new and default configs
 */
export const diffOtherSettings = (
  newConfig: CallUIConfigType,
  defaultConfig: CallUIConfigType
) => {
  const newOtherSettings = newConfig.callConfig.other_settings;
  const defaultOtherSettings = defaultConfig.callConfig.other_settings;
  if (!newOtherSettings || !defaultOtherSettings) {
    return undefined;
  }
  const diff: Record<string, any> = {};
  for (const key in newOtherSettings) {
    if (!isEqual(newOtherSettings[key], defaultOtherSettings[key])) {
      diff[key] = newOtherSettings[key];
    }
  }
  return diff;
};

export const decodeToCallUIConfig = (
  defaultCallUIConfig: CallUIConfigType,
  encodedConfig: string | null
): CallUIConfigType | null => {
  if (!encodedConfig) {
    return null;
  }
  const rawConfig = JSON.parse(atob(encodedConfig));
  const sanitizedConfig = sanitizeCallUIConfig(defaultCallUIConfig, rawConfig);
  const mergedConfig = mergeCallUIConfig(defaultCallUIConfig, sanitizedConfig);
  return mergedConfig;
};

export const encodeCallUIConfig = (
  config: CallUIConfigType,
  defaultCallUIConfig: CallUIConfigType
): string => {
  const diff = diffOtherSettings(config, defaultCallUIConfig);
  if (diff) {
    config.callConfig.other_settings = diff;
  }
  console.log("Encoded Config:", config);
  const encodedConfig = btoa(JSON.stringify(config));
  return encodedConfig;
};

export const sanitizeCallUIConfig = (
  defaultCallUIConfig: CallUIConfigType,
  newCallConfig: Record<string, any>
): CallUIConfigType => {
  // Remove any access fields from the potential call config compared to DEFAULT_CALL_UI_CONFIG
  const callConfig: Record<string, any> = {};
  for (const key in newCallConfig) {
    if (key in defaultCallUIConfig) {
      callConfig[key] = newCallConfig[key];
    } else if (key !== "serverVersion") {
      console.warn(
        `Invalid key (${key} -> ${newCallConfig[key]}) in call config and not in the default`
      );
    }
  }
  return callConfig as CallUIConfigType;
};

export const getCallConfigFromCallDetails = (call: CallDetailsType) => {
  const config: CallConfigType = {
    phone_number: call.call_parameters.phone_number,
    method: getCallTypeToMethod(call.call_type),
    script_id: call.call_parameters.script_id,
    nurse_id: call.call_parameters.nurse_id,
    kickout_sensitivity_id: call.call_parameters.kickout_sensitivity_id,
    scenario_id: call.call_parameters.scenario_id,
    llm_model: call.call_parameters.llm_model,
    llm_streaming: call.call_parameters.llm_streaming,
    tts_streaming: call.call_parameters.tts_streaming,
    asr_service: call.call_parameters.asr_service,
    support_engines: call.call_parameters.support_engines,
    other_settings: call.call_parameters.other_settings,
  };
  return config;
};

export const getAgentIdFromCallParameters = (call?: CallDetailsType) => {
  if (!call) {
    return null;
  }
  if (!call.call_parameters.agent_id) {
    console.warn(
      "Agent ID is not set in the call parameters, defaulting to nurse ID"
    );
  }
  return call.call_parameters.agent_id ?? call.call_parameters.nurse_id;
};

/**
 * Converting old call configs to the new data model
 * - Populating the agent_id off of nurse_id if it does not exist
 *
 * @param callConfig
 * @returns callConfig
 */
export const migrateCallConfig = (callConfig: CallConfigType) => {
  callConfig.agent_id = callConfig.agent_id || callConfig.nurse_id;
  return callConfig;
};
