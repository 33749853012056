import { useContext } from "react";
import { AuthenticationContext } from "~/AuthenticationProvider";

const useCurrentUser = () => {
  const { user } = useContext(AuthenticationContext);
  if (!user) throw new Error("User not found in context");
  return user;
};

export default useCurrentUser;
